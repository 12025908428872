<template>
  <AppLayout>
    <template v-slot:appContent>
      <div>
        <div class="d-flex justify-content-between align-items-center pt-2 pt-md-2 pt-xl-0 ">
          <h4 class="">Vacancies/Create</h4>
          <div>
            <router-link :to="{...previousRoute}">
              <span class="glow d-flex align-items-center"><i class='bx bx-undo'></i>&nbsp;Back</span>
            </router-link>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <form action="">
              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <div>
                      <label for="title">Title *</label>
                      <input v-model="postVacancyData.title" type="text" class="form-control" id="title"
                             placeholder="Enter Title">
                    </div>
                    <div v-if="errors.vacancy.title" class="text-danger">{{ errors.vacancy.title }}</div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group">
                    <div>
                      <label>Short Description *</label>
                      <textarea v-model="postVacancyData.short_description" id="shortDescription"
                                class="form-control"></textarea>
                    </div>
                    <div v-if="errors.vacancy.shortDescription" class="text-danger">{{
                        errors.vacancy.shortDescription
                      }}
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group">
                    <label>Description *</label>
                    <QuillEditor v-model:content="postVacancyData.description" contentType="html" toolbar="full"
                                 theme="snow"/>
                    <div class="text-danger" v-if="errors.vacancy.description">{{ errors.vacancy.description }}</div>
                  </div>
                </div>
                <div class="col-12 col-sm-12 col-md-6">
                  <div class="form-group">
                    <div>
                      <label for="location">Location</label>
                      <input v-model="postVacancyData.location" type="text" class="form-control" id="location"
                             placeholder="Enter Location">
                    </div>
                    <div v-if="errors.vacancy.location" class="text-danger">{{ errors.vacancy.location }}</div>
                  </div>
                </div>
                <div class="col-12 col-sm-12 col-md-6">
                  <div class="form-group">
                    <div>
                      <label for="experience">Experience</label>
                      <input v-model="postVacancyData.experience" type="text" class="form-control" id="experience"
                             placeholder="Enter Experience">
                    </div>
                    <div v-if="errors.vacancy.experience" class="text-danger">{{ errors.vacancy.experience }}</div>
                  </div>
                </div>
                <div class="col-12 col-sm-12 col-md-4">
                  <div class="form-group">
                    <label>End Date</label>
                    <div class="d-flex align-items-center date-picker-wrapper">
                      <div class="d-flex align-items-center">
                        <i class='bx bx-calendar'></i>
                      </div>
                      <div class="w-100 pl-1 ">
                        <div class="w-100 ">
                          <DatePicker v-model="postVacancyData.end_date"
                                      :update-on-input="true" :masks="{input: ['DD MMMM YYYY']}"
                                      :model-config="{ type: 'string', mask: 'YYYY-MM-DD'}">
                            <template v-slot="{ inputValue, inputEvents }">
                              <input :value="inputValue" v-on="inputEvents" class="form-control"/>
                            </template>
                          </DatePicker>
                        </div>
                      </div>
                    </div>
                    <div class="text-danger" v-if="errors.vacancy.endDate">{{ errors.vacancy.endDate }}</div>
                  </div>
                </div>
                <div class="col-12 col-sm-12 col-md-4">
                  <div class="form-group">
                    <div>
                      <label for="workingHour">Working Hour</label>
                      <input v-model="postVacancyData.working_hour" type="text" class="form-control" id="workingHour"
                             placeholder="Enter Working Hour Details">
                    </div>
                    <div v-if="errors.vacancy.workingHour" class="text-danger">{{ errors.vacancy.workingHour }}</div>
                  </div>
                </div>
                <div class="col-12 col-sm-12 col-md-4">
                  <div class="form-group">
                    <div>
                      <label for="workingDay">Working Day</label>
                      <input v-model="postVacancyData.working_day" type="text" class="form-control" id="workingDay"
                             placeholder="Enter Working Day Details">
                    </div>
                    <div v-if="errors.vacancy.workingDay" class="text-danger">{{ errors.vacancy.workingDay }}</div>
                  </div>
                </div>
                <div class="col-12 col-sm-12 col-md-4">
                  <div class="form-group">
                    <div>
                      <label for="totalVacancy">Total Vacancy</label>
                      <input v-model="postVacancyData.total_vacancy" type="number" step="1" min="1" class="form-control"
                             id="totalVacancy" placeholder="Enter Total Vacancy">
                    </div>
                    <div v-if="errors.vacancy.totalVacancy" class="text-danger">{{ errors.vacancy.totalVacancy }}</div>
                  </div>
                </div>

                <div class="col-12 col-sm-12 col-md-4">
                  <div class="form-group">
                    <label>Type</label>
                    <VueMultiselect v-model="selectedType" class="" :options="typeList" :close-on-select="true"
                                    placeholder="Select type" label="name" track-by="name" :show-labels="false"
                                    :allow-empty="false"/>
                    <div class="text-danger" v-if="errors.vacancy.type">{{ errors.vacancy.type }}</div>
                  </div>
                </div>

                <div class="col-12 col-sm-12 col-md-4">
                  <div class="form-group">
                    <div>
                      <label for="applyEmailAddress">Apply Email Address</label>
                      <input v-model="postVacancyData.apply_email_address" type="email" class="form-control"
                             id="applyEmailAddress" placeholder="Enter Apply Email Address">
                    </div>
                    <div v-if="errors.vacancy.applyEmailAddress" class="text-danger">{{
                        errors.vacancy.applyEmailAddress
                      }}
                    </div>
                  </div>
                </div>

                <div class="col-12 col-sm-12 col-md-8">
                  <div class="form-group">
                    <div>
                      <label for="emailSubject">Email Subject</label>
                      <input v-model="postVacancyData.email_subject" type="text" class="form-control" id="emailSubject"
                             placeholder="Enter Email Subject">
                    </div>
                    <div v-if="errors.vacancy.emailSubject" class="text-danger">{{ errors.vacancy.emailSubject }}</div>
                  </div>
                </div>

                <div class="col-12 col-sm-12 col-md-4">
                  <div class="form-group">
                    <div class="controls">
                      <label>Status</label>
                      <div style="margin-top: .5rem">
                        <div class="custom-control custom-switch custom-control-inline mb-1 d-flex align-items-center">
                          <div>
                            <input v-model="selectedStatus" type="checkbox" class="custom-control-input" checked
                                   id="vacancyStatus">
                            <label class="custom-control-label mr-1" for="vacancyStatus"></label>
                          </div>
                          <span class="font-medium-1">{{ selectedStatus ? "Active" : "Inactive" }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="text-danger" v-if="errors.vacancy.status">{{ errors.vacancy.status }}</div>
                  </div>
                </div>
              </div>
              <div class="row mb-4">
                <div class="col-12">
                  <div class="d-flex justify-content-end">
                    <button @click="createSingleVacancy" type="button" class="btn btn-primary update-todo px-4">Create
                      Vacancy
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </template>
  </AppLayout>
</template>

<script>
// components
import AppLayout from '@/layouts/backEnd/AppLayout.vue'

// core packages
import {mapActions, mapGetters} from 'vuex';

// packages
import VueMultiselect from 'vue-multiselect';
import {DatePicker} from 'v-calendar';
import {QuillEditor} from '@vueup/vue-quill';

// mixins
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";

// css
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import Authorization from "@/components/backEnd/mixins/Authorization";

export default {
  components: {
    AppLayout,
    VueMultiselect,
    QuillEditor,
    DatePicker

  },
  name: "VacancyCreate",

  mixins: [ShowToastMessage, Loader, Authorization],

  data() {
    return {
      getSettingsParams: {
        type: ['vacancy'],
        key: ['vacancy_apply_email_address'],
      },
      postVacancyData: {
        title: '',
        short_description: '',
        description: '',
        location: '',
        end_date: '',
        experience: '',
        working_hour: '',
        working_day: '',
        total_vacancy: 1,
        type: 0,
        apply_email_address: '',
        email_subject: '',
        status: 1,
      },

      typeList: [
        {
          name: 'Full time',
          value: 0,
        },
        {
          name: 'Part time',
          value: 1,
        }
      ],

      selectedType: {
        name: 'Full time',
        value: 0,
      },

      selectedStatus: true,

      errors: {
        vacancy: {
          title: '',
          shortDescription: '',
          description: '',
          location: '',
          endDate: '',
          experience: '',
          workingHour: '',
          workingDay: '',
          totalVacancy: '',
          type: '',
          applyEmailAddress: '',
          emailSubject: '',
          status: ''
        }
      }
    }
  },

  computed: {
    ...mapGetters({
      previousRoute: 'previousRoute',
      settingVacancyApplyEmailAddress: 'appSettings/settingVacancyApplyEmailAddress',
    }),
    redirectRouteName() {
      return this.can('vacancy-view-any') ? 'appVacancyList' : 'appVacancyCreate';
    }
  },

  watch: {
    selectedType(selectedType) {
      this.postVacancyData.type = selectedType.value;
    },

    selectedStatus(selectedStatus) {
      this.postVacancyData.status = selectedStatus === true ? 1 : 0;
    }
  },

  methods: {
    ...mapActions({
      postVacancy: 'appVacancies/postVacancy',
      getSettings: 'appSettings/getSettings',
    }),

    async getSettingList() {
      await this.getSettings(this.getSettingsParams);
    },

    async createSingleVacancy() {
      this.loader(true);
      await this.postVacancy(this.postVacancyData).then(async (response) => {
        this.loader(false);
        if (response.status === 200 || response.status === 201) {
          const toastObj = {
            message: 'Vacancy created successful.',
            type: 'success'
          };

          this.showToastMessage(toastObj);

          await this.$router.push({
            name: this.redirectRouteName,
          });

        }

        this.errors.vacancy.title = response.errors && response.errors.title ? response.errors.title[0] : '';
        this.errors.vacancy.shortDescription = response.errors && response.errors.short_description ? response.errors.short_description[0] : '';
        this.errors.vacancy.description = response.errors && response.errors.description ? response.errors.description[0] : '';
        this.errors.vacancy.location = response.errors && response.errors.location ? response.errors.location[0] : '';
        this.errors.vacancy.endDate = response.errors && response.errors.end_date ? response.errors.end_date[0] : '';
        this.errors.vacancy.experience = response.errors && response.errors.experience ? response.errors.experience[0] : '';
        this.errors.vacancy.workingHour = response.errors && response.errors.working_hour ? response.errors.working_hour[0] : '';
        this.errors.vacancy.workingDay = response.errors && response.errors.working_day ? response.errors.working_day[0] : '';
        this.errors.vacancy.totalVacancy = response.errors && response.errors.total_vacancy ? response.errors.total_vacancy[0] : '';
        this.errors.vacancy.type = response.errors && response.errors.type ? response.errors.type[0] : '';
        this.errors.vacancy.applyEmailAddress = response.errors && response.errors.apply_email_address ? response.errors.apply_email_address[0] : '';
        this.errors.vacancy.emailSubject = response.errors && response.errors.email_subject ? response.errors.email_subject[0] : '';
        this.errors.vacancy.status = response.errors && response.errors.status ? response.errors.status[0] : '';

        if (response.message) {
          this.showToastMessage(response);
        }

      })
    }
  },
  async mounted() {
    this.loader(true);
    await this.getSettingList().then(() => {
      this.postVacancyData.apply_email_address = this.settingVacancyApplyEmailAddress.value ?? '';
    });
    this.loader(false);
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style scoped>

.multiselect__tags:focus-within {

  /* border:1px solid red !important; */
  border-color: #5A8DEE;

}

.multiselect__tags {
  min-height: 0;
  max-height: 38px !important;
  border-color: #DFE3E7;
}

.ql-editor {
  min-height: 200px !important;
}
</style>
